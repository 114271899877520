import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useForm from 'react-hook-form'

import TextField from '@material-ui/core/TextField'
import { GET_SHOPCONFIG, UPDATE_CONFIG } from '../graphql/config'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

// START
export default function ConfigDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()

  const updateCache = (cache, { data }) => {
    props.refetch()
  }

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    update: updateCache,
  })

  const onSubmit = (values) => {
    updateConfig({
      variables: {
        formKey: props.formKey,
        formValue: values.formValue,
      },
    })
    props.setOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            {props.formTitle}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <TextField
                    name="formValue"
                    label=""
                    margin="normal"
                    fullWidth
                    multiline
                    shrink
                    placeholder={props.formPlaceholder}
                    defaultValue={props.formValue}
                    autoComplete="off"
                    inputRef={register({})}
                    error={errors.FieldValue}
                    helperText={errors.FieldValue && errors.FieldValue.message}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
