import { gql } from 'apollo-boost'

export const GET_BRANCHES = gql`
  {
    branches {
      BranchName
      BranchCode
    }
  }
`
