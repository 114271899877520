import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment-timezone'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  UPDATE_ORDER,
  GET_ORDERS,
  CREATE_ORDER,
  REMOVE_ORDER,
  GET_bookingdetails,
  GET_ORDER,
  RECEIVED_ORDER,
} from '../graphql/order'
import { GET_BOOKINGDETAILS, RECEIVED_BOOKING } from '../graphql/booking'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import AutocompleteField from '../common/autocompleteField'
import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

// START
function BookingDetails(props) {
  const classes = useStyles()

  const token = props.match.params.token
  const {
    loading: loadingBookingDetails,
    refetch,
    data: { bookingdetails } = { bookingdetails: {} },
  } = useQuery(GET_BOOKINGDETAILS, {
    variables: { Token: token },
    skip: !token,
  })

  const [staff, setStaff] = useState('')
  const [staffName, setStaffName] = useState('')

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  /* 
  const {
    loading: loadingOrder,
    data: { order } = { order: {} },
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { Token: token },
    skip: !token,
  })
 */
  const updateCache = (cache, { data }) => {
    refetch()
  }

  /*

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.createorder
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [latest, ...old.orders] },
    })
  }

  const [createOrder] = useMutation(CREATE_ORDER, { update: addCache }) */
  const [receivedBooking] = useMutation(RECEIVED_BOOKING, {
    update: updateCache,
  })

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  /*   const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  }) */

  const onSubmit = (values) => {
    /* if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } */
  }

  const handleClickCollected = () => {
    receivedBooking({
      variables: {
        Token: token,
        SalesPerson: staff,
      },
    })
    setConfirm(false)
  }

  /*  useEffect(() => {
    if (bookingdetails.length == 0) return

    const totalAmount = bookingdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [bookingdetails]) */

  /* useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount]) */

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <ConfirmationDialog
          action={handleClickCollected}
          confirm={confirm}
          setConfirm={setConfirm}
          message="Confirm check in?"
          okButton="Yes"
          title="Check In"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <pre>{JSON.stringify(bookingdetails, 0, 4)}</pre> */}
          <Grid container spacing={3}>
            <Grid item md={6} xs={6}>
              <TextFieldDisplay
                label="Branch"
                value={bookingdetails.BranchName}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <TextFieldDisplay
                label="Date"
                value={`${Moment(bookingdetails.BookFrom)
                  .tz('UTC')
                  .format('DD/MM/YYYY')}`}
              />
            </Grid>
            {/* 
          <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
            <TextFieldDisplay
              label="Order Total"
              value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
            />
          </Grid> */}
          </Grid>
          <Divider classes={{ root: classes.dividerroot }} />
          <Grid container spacing={3}>
            <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
              <TextFieldDisplay
                label="Time"
                value={`${Moment(bookingdetails.BookFrom)
                  .tz('UTC')
                  .format('LT')} - ${Moment(bookingdetails.BookTo)
                  .tz('UTC')
                  .format('LT')}`}
              />

              <TextFieldDisplay
                label="Name"
                value={`${bookingdetails.FirstName} ${bookingdetails.LastName}`}
              />

              <TextFieldDisplay
                label="Phone"
                value={bookingdetails.CreatedBy}
              />
            </Grid>
            <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
              <TextFieldDisplay
                label="Total Hours"
                value={bookingdetails.TotalHours}
              />

              <TextFieldDisplay
                label="Remaining Passes"
                value={bookingdetails.ServiceTimes}
              />
            </Grid>
          </Grid>
          <Divider classes={{ root: classes.dividerroot }} />
        </form>
        {!bookingdetails.ReceivedBy && (
          <div>
            <AutocompleteField
              name="buyer"
              label="Assign Customer Service"
              value={staffName}
              options={officestaffs.map(
                (item) =>
                  `${item.Username} — ${item.FirstName} ${item.LastName}`
              )}
              onChange={(e, value) => {
                const buyerValue = officestaffs.find(
                  (item) =>
                    `${item.Username} — ${item.FirstName} ${item.LastName}` ===
                    value
                )
                if (buyerValue) {
                  setStaff(buyerValue.Username)
                  setStaffName(
                    `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                  )
                }
              }}
            />
            <br />
            <Button
              disabled={!staff}
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                setConfirm(true)
              }}
            >
              Check in customer
            </Button>
          </div>
        )}
        {bookingdetails.ReceivedBy && (
          <Alert severity="success">
            Member checked in. Customer Service assigned to{' '}
            {bookingdetails.ReceivedBy}
            {'.'}
          </Alert>
        )}
      </Container>
    </div>
  )
}

export default BookingDetails
