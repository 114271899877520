import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { Link, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useMutation, useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { GET_MYPASS } from '../graphql/order'
import { GET_BUYERS } from '../graphql/buyer'
import {
  INSERT_BOOKING,
  GET_BOOKINGS,
  REMOVE_BOOKING,
} from '../graphql/booking'
import Cookies from 'js-cookie'
import Moment from 'moment-timezone'

import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import QRCode from 'qrcode.react'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleTitle from '../common/singleTitle'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import Fade from '@material-ui/core/Fade'
import TextFieldDisplay from '../common/textFieldDisplay'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AutocompleteField from '../common/autocompleteField'

const restApi = 'http://localhost:3050'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    padding: 0,
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: 20,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  menuitem: {
    textAlign: 'left',
    display: 'block',
  },
  avatar: {
    color: 'white',
    backgroundColor: '#00acee',
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Booking(props) {
  const classes = useStyles()
  const history = useHistory()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [showForm, setShowForm] = useState(true)
  const [showActive, setShowActive] = useState(false)
  const [branch, setBranch] = useState()
  const [time, setTime] = useState()
  const [totalHours, setTotalHours] = useState()
  const [booking, setBooking] = useState()
  const [confirm, setConfirm] = useState(false)

  const [customer, setCustomer] = useState('')
  const [customerName, setCustomerName] = useState('')

  const {
    data: { bookings } = { bookings: [] },
    refetch: refetchBooking,
  } = useQuery(GET_BOOKINGS)

  const {
    loading: loadingBuyers,
    data: { buyers } = { buyers: [] },
  } = useQuery(GET_BUYERS)

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const addCache = (cache, { data }) => {
    if (data.insertbooking == 3) {
      alert('Time Slot is taken. Please try another time slot.')
    }

    refetchBooking()
  }

  const [insertBooking] = useMutation(INSERT_BOOKING, {
    update: addCache,
  })

  const removeCache = (cache, { data }) => {
    refetchBooking()
  }

  const [removeBooking] = useMutation(REMOVE_BOOKING, {
    update: removeCache,
  })

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  /* useEffect(() => {
    setBooking()
    setShowForm(true)
    if (bookings.find((a) => !a.ReceivedBy)) {
      setShowForm(false)
      setBooking(bookings.find((a) => !a.ReceivedBy))
    }
  }, [bookings]) */

  const handleSubmit = () => {
    if (customer && selectedDate && time) {
      console.log(branch)
      insertBooking({
        variables: {
          CustomerID: customer,
          BookDate: selectedDate,
          BookTime: time,
          /* TotalHours: parseFloat(totalHours), */
          TotalHours: 1,
        },
      })
      props.setOpen(false)
    } else {
      alert('Please fill in the form')
    }
  }

  const handleClickDelete = () => {
    removeBooking({
      variables: {
        id: booking.id,
      },
    })
    setConfirm(false)
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to cancel Booking?"
        okButton="Yes"
        title=""
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            New Booking
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <Container maxWidth="md">
              <br />
              <br />
              <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <pre>here{JSON.stringify(booking, 0, 4)}</pre> */}
                  {showForm && (
                    <div>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          {!loadingBuyers && (
                            <AutocompleteField
                              name="buyer"
                              label="Customer"
                              value={customerName}
                              options={buyers.map(
                                (item) =>
                                  `${item.Phone} — ${item.FirstName} ${item.LastName}`
                              )}
                              onChange={(e, value) => {
                                const buyerValue = buyers.find(
                                  (item) =>
                                    `${item.Phone} — ${item.FirstName} ${item.LastName}` ===
                                    value
                                )
                                if (buyerValue) {
                                  setCustomer(buyerValue.UserID)
                                  setCustomerName(
                                    `${buyerValue.Phone} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                                  )
                                }
                              }}
                            />
                          )}
                        </Grid>
                        {/* <Grid item xs={7}>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink id="localtion-label">
                              Location
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="localtion-label"
                              value={branch}
                              onChange={(e, value) => {
                                setBranch(e.target.value)
                              }}
                            >
                              <MenuItem value="spa2">Kempas, Johor</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid> */}
                      </Grid>
                      <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={7}>
                          <KeyboardDatePicker
                            keyboardIcon={
                              <i className="fal fa-calendar-day fa-1x"></i>
                            }
                            margin="normal"
                            id="date-picker-dialog"
                            label="Booking Date"
                            format="dd/MM/yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 15 }}>
                        <Grid item xs={4}>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink id="localtion-label">
                              Time
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="localtion-label"
                              value={time}
                              onChange={(e, value) => {
                                setTime(e.target.value)
                              }}
                            >
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="08:00"
                              >
                                8 am
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="09:00"
                              >
                                9 am
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="10:00"
                              >
                                10 am
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="11:00"
                              >
                                11 am
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="12:00"
                              >
                                12 pm
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="13:00"
                              >
                                1 pm
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="14:00"
                              >
                                2 pm
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="15:00"
                              >
                                3 pm
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="16:00"
                              >
                                4 pm
                              </MenuItem>
                              <MenuItem
                                classes={{ root: classes.menuitem }}
                                value="17:00"
                              >
                                5 pm
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* <Fade in={branch}>
                    <Grid container style={{ marginTop: 25 }}>
                      <Grid item xs={5}>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink id="totalhours-label">
                            Duration
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="totalhours-label"
                            value={totalHours}
                            onChange={(e, value) => {
                              setTotalHours(e.target.value)
                            }}
                          >
                            <MenuItem value="1">1 hour</MenuItem>
                            <MenuItem value="1.5">1.5 hours</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Fade> */}

                      <div style={{ marginTop: 50, marginBottom: 50 }}>
                        <Button
                          disableElevation
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => {
                            handleSubmit()
                          }}
                        >
                          Make Booking
                        </Button>
                      </div>
                    </div>
                  )}
                </MuiPickersUtilsProvider>

                {/* {!showForm && (
              <Grid style={{ marginTop: 0 }} container spacing={10}>
                <Grid item sm={12} md={5}>
                  <div style={{ marginBottom: 20 }}>
                    <i
                      class="fad fa-check-circle"
                      style={{ color: '#00acee' }}
                    ></i>{' '}
                    One(1) Booking Pass has been deducted. <br />
                    <br />
                    Please present this code to our Customer Service counter to
                    Check In during your booking time.
                  </div>
                  {!booking.ReceivedBy && (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: 80,
                      }}
                    >
                      <QRCode
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                        }}
                        value={`http://picturesofpeoplescanningqrcodes.tumblr.com/${booking.Token}`}
                        bgColor={'#ffffff'}
                        size={300}
                        fgColor={'#000000'}
                        level={'L'}
                        includeMargin={false}
                        renderAs={'svg'}
                        imageSettings={{
                          src: '/images/logo2.png',
                          x: null,
                          y: null,
                          height: 38,
                          width: 38,
                          excavate: true,
                        }}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      //history.push('/booking/1')
                      setConfirm(true)
                    }}
                  >
                    Cancel Booking
                  </Button>
                </Grid>
                <Grid item sm={12} md={7}>
                  <Grid container spacing={3}>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      <TextFieldDisplay
                        label="Branch"
                        value={booking.BranchName}
                      />
                      <TextFieldDisplay
                        label="Booking Date & Time"
                        value={Moment(booking.BookFrom)
                          .tz('UTC')
                          .format('L - LT')}
                      />
                      <TextFieldDisplay
                        label="Duration"
                        value={`${booking.TotalHours} h`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )} */}
              </div>
            </Container>{' '}
          </DialogContent>
          {/* <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions> */}
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
