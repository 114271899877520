import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment-timezone'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ORDERDETAILS, RECEIVED_ORDER, GET_ORDER } from '../graphql/order'
import {
  GET_orderdetails,
  RECEIVED_BOOKING,
  CANCEL_BOOKING,
  GET_BOOKINGS,
} from '../graphql/booking'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import AutocompleteField from '../common/autocompleteField'
import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'
import OrderDetailsItem from './orderDetailsItem'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),

    paddingBottom: theme.spacing(2),
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submitBtn: {
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
    },
  },
}))

// START
export default function OrderDetailsDialog(props) {
  const classes = useStyles()
  const [token, setToken] = useState(props.data && props.data.Token)

  const {
    loading: loadingOrderDetails,
    data: { orderdetails } = { orderdetails: [] },
  } = useQuery(GET_ORDERDETAILS, {
    variables: { Token: token },
    skip: !token,
  })

  const {
    loading: loadingOrder,
    data: { order } = { order: {} },
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { Token: token },
    skip: !token,
  })

  const [staff, setStaff] = useState('')
  const [staffName, setStaffName] = useState('')

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  const updateCache = (cache, { data }) => {
    props.refetchRemaining()
    refetch()
  }

  const [receivedOrder] = useMutation(RECEIVED_ORDER, {
    update: updateCache,
  })

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BOOKINGS,
    })
    if (data.cancelbooking) {
      const latest = old.bookings.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_BOOKINGS,
        data: { bookings: [...latest] },
      })
    }
  }

  const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    /* if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } */
  }

  const handleClickCollected = () => {
    receivedOrder({
      variables: {
        Token: token,
        SalesPerson: staff,
      },
    })
    setConfirm(false)
    props.setOpen(false)
  }
  /* 
  const handleClickDelete = () => {
    cancelBooking({
      variables: {
        id: props.data.id,
      },
    })
    setConfirmDelete(false)
    props.setOpen(false)
  } */

  /*  useEffect(() => {
    if (orderdetails.length == 0) return

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails]) */

  /* useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount]) */

  useEffect(() => {
    if (orderdetails.length == 0) return

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails])

  useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      scroll="body"
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      disableBackdropClick={false}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="manifesting-dialog"
    >
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirmDelete}
        setConfirm={setConfirmDelete}
        message="Continue remove Booking?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <React.Fragment>
        <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
          Booking Details
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              props.setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.root}>
            <ConfirmationDialog
              action={handleClickCollected}
              confirm={confirm}
              setConfirm={setConfirm}
              message="Confirm check in?"
              okButton="Yes"
              title="Check In"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={6}>
                      <TextFieldDisplay
                        label="Total"
                        value={`RM ${parseFloat(totalAmount).toFixed(2)}`}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TextFieldDisplay
                        label="Date"
                        value={`${Moment(order.CreatedOn).format('L')} ${Moment(
                          order.CreatedOn
                        ).format('LT')}`}
                      />
                    </Grid>
                    {/* 
          <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
            <TextFieldDisplay
              label="Order Total"
              value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
            />
          </Grid> */}
                  </Grid>
                  <Divider classes={{ root: classes.dividerroot }} />
                  <Grid container spacing={3}>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      <TextFieldDisplay
                        label="Name"
                        value={`${order.FirstName} ${order.LastName}`}
                      />

                      <TextFieldDisplay label="Phone" value={order.Phone} />

                      <TextFieldDisplay label="Email" value={order.Email} />
                    </Grid>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      <TextFieldDisplay
                        label="Address 1"
                        value={order.Address1}
                      />

                      <TextFieldDisplay
                        label="Address 2"
                        value={order.Address2}
                      />

                      <TextFieldDisplay label="City" value={order.City} />

                      <TextFieldDisplay
                        label="Postcode"
                        value={order.PostCode}
                      />

                      <TextFieldDisplay label="State" value={order.State} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  {/* <pre>{JSON.stringify(order, 0, 4)}</pre> */}
                  <Divider classes={{ root: classes.dividerroot }} />
                  {orderdetails.map(
                    (a, i) =>
                      a.Category == '1' && <OrderDetailsItem key={i} data={a} />
                  )}
                  {/* {JSON.stringify(props.data, 0, 0, 4)} */}
                </Grid>
              </Grid>
            </form>
            {!order.ReceivedBy && (
              <div>
                <Hidden only={['xs', 'sm']}>
                  <Divider classes={{ root: classes.dividerroot }} />
                </Hidden>
                <Grid container spacing={3}>
                  <Grid item md={3} xs={12}>
                    <AutocompleteField
                      name="buyer"
                      label="Assign Customer Service"
                      value={staffName}
                      options={officestaffs.map(
                        (item) =>
                          `${item.Username} — ${item.FirstName} ${item.LastName}`
                      )}
                      onChange={(e, value) => {
                        const buyerValue = officestaffs.find(
                          (item) =>
                            `${item.Username} — ${item.FirstName} ${item.LastName}` ===
                            value
                        )
                        if (buyerValue) {
                          setStaff(buyerValue.Username)
                          setStaffName(
                            `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                          )
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      className={classes.submitBtn}
                      disabled={!staff}
                      variant="contained"
                      disableElevation
                      color="primary"
                      onClick={() => {
                        setConfirm(true)
                      }}
                    >
                      Collected Order
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
            {order.ReceivedBy && (
              <Alert severity="success">
                Order collected. Customer Service assigned to {order.ReceivedBy}
                {'.'}
              </Alert>
            )}
          </div>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  )
}
