import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import { UPDATE_STOCK, CREATE_STOCK, GET_STOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const [confirm, setConfirm] = useState(false)
  const [product, setProduct] = useState()
  const [productName, setProductName] = useState()

  const { data: { products } = { products: [] } } = useQuery(GET_PRODUCTS)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STOCKS,
      variables: {
        Movement: 'In',
      },
    })
    const latest = data.createstock
    cache.writeQuery({
      query: GET_STOCKS,
      variables: {
        Movement: 'In',
      },
      data: { stocks: [latest, ...old.stocks] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setStock()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_STOCKS,
      variables: {
        Movement: 'In',
      },
    })

    const latest = data.updatestock

    const foundIndex = old.stocks.findIndex((item) => item.id === latest.id)
    old.stocks.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_STOCKS,
      variables: {
        Movement: 'In',
      },
      data: { stocks: [...old.stocks] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setStock()
  }
  /* 
  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: PUNCHCARD_HISTORY,
    })
    if (data.removepunchcard) {
      const latest = old.punchcardhistory.filter(
        (item) => item.id !== props.data.id
      )
      cache.writeQuery({
        query: PUNCHCARD_HISTORY,
        data: { punchcardhistory: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setPunchcard()
    }
  } */

  const [insertStock] = useMutation(CREATE_STOCK, { update: addCache })
  const [updateStock] = useMutation(UPDATE_STOCK, {
    update: updateCache,
  })
  /* const [removepunchcard] = useMutation(REMOVE_PUNCHCARD, {
    update: deleteCache,
  }) */
  /*  const handleClickDelete = () => {
    removepunchcard({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  } */

  useEffect(() => {
    if (!props.data) return

    setProduct(`${props.data.ProductID}`)
    setProductName(`${props.data.ProductName}`)
  }, [props])

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateStock({
        variables: {
          id: props.data.id,
          ProductID: product,
          Qty: parseInt(values.Qty),
        },
      })
    } else {
      insertStock({
        variables: {
          ProductID: product,
          Qty: parseInt(values.Qty),
        },
      })
    }
  }

  return (
    <React.Fragment>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove PunchCard?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Stocks
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <AutocompleteField
                      name="ProductID"
                      label="Product"
                      value={productName}
                      options={products
                        .filter((item) => item.Category == '1')
                        .map((item) => `${item.ProductName}`)}
                      onChange={(e, value) => {
                        const productValue = products.find(
                          (item) => `${item.ProductName}` === value
                        )
                        if (productValue) {
                          setProduct(productValue.id)
                          setProductName(`${productValue.ProductName}`)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Qty"
                      label="Qty"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Qty}
                      style={{ width: 120 }}
                      autoComplete="off"
                      type="number"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Qty}
                      helperText={errors.Qty && errors.Qty.message}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
