import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import BookingDisplay from './orderDisplay'
import BookingDialog from './orderDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import OrderScanDialog from './orderScanDialog'
import OrderScanBookingDialog from './orderScanBookingDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'OrderNo',
    numeric: false,
    disablePadding: false,
    label: 'Order',
  },
  {
    id: 'FinalTotal',
    currency: true,
    disablePadding: false,
    label: 'Total',
  },

  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'CreatedBy',
    logdate: 'CreatedOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Order Date',
    width: 300,
  },
]

export default function Order() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [order, setOrder] = useState({})
  const [openScan, setOpenScan] = useState(false)
  const [openScanBooking, setOpenScanBooking] = useState(false)

  const {
    loading,
    error,
    data: { orders } = { orders: [] },
    refetch: refetchOrders,
  } = useQuery(GET_ORDERS)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== selected[0])
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
      setSelected([])
      setOrder()
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  })

  const handleClickOpen = () => {
    /* setOrder()
    setSelected([]) */
    setOpenScan(true)
  }

  const handleBookingClickOpen = () => {
    /* setOrder()
    setSelected([]) */
    setOpenScanBooking(true)
  }

  const handleClickDelete = () => {
    removeOrder({
      variables: {
        id: selected[0],
      },
    })
    setConfirm(false)
    setSelected([])
    setOrder()
  }

  if (loading) return <Loading />
  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Order?"
        okButton="Yes"
        title="Continue remove"
      />
      <OrderScanDialog setOpen={setOpenScan} open={openScan} />

      <OrderScanBookingDialog
        setOpen={setOpenScanBooking}
        open={openScanBooking}
      />

      <Button
        variant="contained"
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        Scan QR Order Collection
      </Button>

      <br />
      <Button
        variant="contained"
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleBookingClickOpen}
      >
        Scan QR Booking Check In
      </Button>
      {/* <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchOrders()
        }}
      >
        Refresh
      </Button> */}
      {/* <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <BookingDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setOrder={setOrder}
            data={order}
            open={open}
            refetchOrders={refetchOrders}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setOrder}
            tableData={orders}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid> */}
      {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <BookingDisplay data={order} />
            </div>
          )}
        </Grid> */}
      {/* </Grid> */}
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
