import { gql } from 'apollo-boost'

export const GET_BOOKINGS = gql`
  {
    bookings {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
    }
  }
`
export const GET_BOOKINGDETAILS = gql`
  query BookingDetails($Token: ID) {
    bookingdetails(Token: $Token) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
    }
  }
`

export const RECEIVED_BOOKING = gql`
  mutation ReceivedBooking($Token: ID, $SalesPerson: String) {
    receivedbooking(Token: $Token, SalesPerson: $SalesPerson) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($id: ID) {
    cancelbooking(id: $id)
  }
`

export const INSERT_BOOKING = gql`
  mutation InsertBooking(
    $CustomerID: String
    $BookDate: Date
    $BookTime: String
    $TotalHours: Float
  ) {
    insertbooking(
      CustomerID: $CustomerID
      BookDate: $BookDate
      BookTime: $BookTime
      TotalHours: $TotalHours
    )
  }
`

export const REMOVE_BOOKING = gql`
  mutation RemoveBooking($id: ID) {
    removebooking(id: $id)
  }
`
