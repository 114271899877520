import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  INSERT_MEMBER,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
  GET_MEMBERS,
  GET_MEMBERORDERS,
} from '../graphql/member'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import TextFieldDisplay from '../common/textFieldDisplay'
import TableHalf from '../common/tableHalf'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'OrderNo',
    label: 'Order No',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
  },
]

export default function MemberDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue, setError } = useForm()
  const [confirm, setConfirm] = useState(false)

  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [order, setOrder] = useState()

  const {
    loading,
    data: { memberorders } = { memberorders: [] },
    refetch: refetchMemberOrders,
  } = useQuery(GET_MEMBERORDERS, {
    variables: {
      UserID: props.data && props.data.UserID,
    },
    skip: !props.data,
  })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_MEMBERS,
    })
    const latest = data.insertmember

    if (latest.Error == 'Username exist') {
      setError('Username', true, 'Username exist. Try another Username.')
      return
    }

    cache.writeQuery({
      query: GET_MEMBERS,
      data: { members: [latest, ...old.members] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setMember()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_MEMBERS,
    })

    const latest = data.updatemember

    const foundIndex = old.members.findIndex((item) => item.id === latest.id)
    old.members.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_MEMBERS,
      data: { members: [...old.members] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setMember()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_MEMBERS,
    })
    if (data.removemember) {
      const latest = old.members.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_MEMBERS,
        data: { members: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setMember()
    }
  }

  const [insertMember] = useMutation(INSERT_MEMBER, { update: addCache })
  const [updateMember] = useMutation(UPDATE_MEMBER, {
    update: updateCache,
  })
  const [removemember] = useMutation(REMOVE_MEMBER, {
    update: deleteCache,
  })
  const handleClickDelete = () => {
    removemember({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (!props.data) return

    // setEmail(props.data.Email)
    // setPhone(props.data.Phone)
    // setFirstName(props.data.FirstName)
  }, [props])

  const onSubmit = (values) => {
    if (values.Username && values.Username.charAt(0) != '0') {
      setError('Username', true, 'Username must start with 0. Eg. 012....')
      return
    }

    if (!values.Password && !props.data) {
      setError('Password', true, 'Please provide a password')
      return
    }

    if (props.data && props.data.id) {
      updateMember({
        variables: {
          id: props.data.id,
          Email: values.Email,
          Phone: values.Phone,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          Postcode: values.Postcode,
          State: values.State,
          Country: 'Malaysia',
          Password: values.Password,
        },
      })
    } else {
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(values.Username)) {
        setError(
          'Username',
          true,
          'Username must not have special characters or space'
        )
        return
      }
      insertMember({
        variables: {
          Email: values.Email,
          Phone: values.Phone,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          Postcode: values.Postcode,
          State: values.State,
          Country: 'Malaysia',
          Username: values.Username,
          Password: values.Password,
        },
      })
    }
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Member?"
        okButton="Yes"
        title="Continue remove"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Member Details
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Username"
                          value={props.data && props.data.Username}
                        />

                        {/* <TextField
                          name="Username"
                          label="Username / Mobile"
                          margin="dense"
                          InputProps={{
                            readOnly: props.data && true,
                          }}
                          fullWidth
                          defaultValue={props.data && props.data.Username}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Username}
                          helperText={
                            errors.Username && errors.Username.message
                          }
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Password"
                          value={props.data && props.data.Password}
                        />

                        {/*  <TextField
                          name="Password"
                          label="Password"
                          margin="dense"
                          read
                          fullWidth
                          defaultValue={props.data && props.data.Password}
                          autoComplete="off"
                          inputRef={register({})}
                          error={errors.Password}
                          helperText={`leave blank if no change`}
                        /> */}
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Email"
                          value={props.data && props.data.Email}
                        />

                        {/*  <TextField
                          name="Email"
                          label="Email"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.Email}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Email}
                          helperText={errors.Email && errors.Email.message}
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}></Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="First Name"
                          value={props.data && props.data.FirstName}
                        />
                        {/* <TextField
                          name="FirstName"
                          label="First Name"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.FirstName}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.FirstName}
                          helperText={
                            errors.FirstName && errors.FirstName.message
                          }
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Last Name"
                          value={props.data && props.data.LastName}
                        />
                        {/* <TextField
                          name="LastName"
                          label="Last Name"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.LastName}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.LastName}
                          helperText={
                            errors.LastName && errors.LastName.message
                          }
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <TextFieldDisplay
                          label="Address 1"
                          value={props.data && props.data.Address1}
                        />
                        {/* <TextField
                          name="Address1"
                          label="Address1"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.Address1}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Address1}
                          helperText={
                            errors.Address1 && errors.Address1.message
                          }
                        /> */}
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextFieldDisplay
                          label="Address 2"
                          value={props.data && props.data.Address2}
                          multiline
                        />
                        {/* <TextField
                          name="Address2"
                          label="Address2"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.Address2}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Address2}
                          helperText={
                            errors.Address2 && errors.Address2.message
                          }
                        /> */}
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="City"
                          value={props.data && props.data.City}
                        />
                        {/* <TextField
                          name="City"
                          label="City"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.City}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.City}
                          helperText={errors.City && errors.City.message}
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Postcode"
                          value={props.data && props.data.Postcode}
                        />
                        {/*  <TextField
                          name="Postcode"
                          label="Postcode"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.Postcode}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Postcode}
                          helperText={
                            errors.Postcode && errors.Postcode.message
                          }
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="State"
                          value={props.data && props.data.State}
                        />
                        {/* <TextField
                          name="State"
                          label="State"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.State}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.State}
                          helperText={errors.State && errors.State.message}
                        /> */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextFieldDisplay
                          label="Country"
                          value={props.data && props.data.Country}
                        />
                        {/* <TextField
                          name="Country"
                          label="Country"
                          margin="dense"
                          fullWidth
                          defaultValue={props.data && props.data.Country}
                          autoComplete="off"
                          inputRef={register({ required: 'Required' })}
                          error={errors.Country}
                          helperText={errors.Country && errors.Country.message}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TableHalf
                      rowsPerPage={10}
                      clickOnSelect={true}
                      hideChange={true}
                      hideDelete={true}
                      setConfirm={setConfirm}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setOrder}
                      tableData={memberorders}
                      setOpen={setOpen}
                      tableHead={tableHead}
                    ></TableHalf>
                  </Grid>
                </Grid>

                <br />
              </DialogContent>
              {/* <DialogActions className={classes.dialogActions}>
                
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions> */}
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
