import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import TransactionOrderDialog from './transactionOrderDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { TRANSACTION_HISTORY } from '../graphql/transaction'
import ConfirmationDialog from '../common/confirmationDialog'
import TransactionDialog from './transactionDialog'
import GetAppIcon from '@material-ui/icons/GetApp'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import DatepickerField from '../common/datepickerField'

const restApi2 = 'https://frapp.cloud/fishfarm'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'TransactionDate',
    date: true,
    label: 'Transaction Date',
  },
  {
    id: 'TransactionType',
    label: 'Transaction Type',
  },
  {
    id: 'TransactionDesc',
    label: 'Transaction Description',
  },
  {
    id: 'ServiceTimes',
    label: 'Booking Pass',
  },
  {
    id: 'UnitPrice',
    label: 'Unit Price',
    currency: true,
  },
  {
    id: 'Qty',
    label: 'Quantity',
  },
  {
    id: 'TransactionAmount',
    label: 'Total Amount',
    currency: true,
  },
  {
    id: 'RefundBy',
    logdate: 'RefundOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Refund',
  },
  {
    id: 'RefundReason',
    label: 'Refund Reason',
  },
  {
    id: 'Client',
    label: 'Branch Code',
  },
]

export default function Leave() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openRefund, setOpenRefund] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [transaction, setTransaction] = useState()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [totalAmount, setTotalAmount] = useState()

  const {
    loading,
    data: { transactionhistory } = { transactionhistory: [] },
    refetch: refetchTransaction,
  } = useQuery(TRANSACTION_HISTORY, { skip: !from || !to })

  const handleClickOpen = () => {
    setTransaction()
    setSelected([])
    setOpen(true)
  }
  const handleClickSearch = () => {
    if (from && to) refetchTransaction({ FromDate: from, ToDate: to })
  }

  const handleSaveCsv = () => {
    const fromDate = from.split('/')
    const toDate = to.split('/')

    console.log(fromDate, toDate)
    window.open(
      `${restApi2}/salestransaction_csv.php?from=${fromDate[2]}-${fromDate[0]}-${fromDate[1]}&to=${toDate[2]}-${toDate[0]}-${toDate[1]}`,
      '_blank'
    )
  }

  useEffect(() => {
    const total = transactionhistory.reduce(
      (a, b) => a + parseInt(b.TransactionAmount),
      0
    )
    setTotalAmount(total)
  }, [transactionhistory])

  //if (loading) return <Loading />

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      {/* <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchTransaction()
        }}
      >
        Refresh
      </Button> */}

      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <DatepickerField
            name="From"
            label="From"
            value={from ? from : null}
            fullWidth
            onChange={(e, value) => {
              setFrom(value)
            }}
            autoComplete="off"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <DatepickerField
            name="To"
            label="To"
            value={to ? to : null}
            fullWidth
            onChange={(e, value) => {
              setTo(value)
            }}
            autoComplete="off"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Button
            disabled={!to || !from}
            variant="contained"
            disableElevation
            style={{ marginTop: 20 }}
            color="primary"
            onClick={handleClickSearch}
          >
            Search
          </Button>
          <Button
            style={{
              marginTop: 20,
              marginLeft: 10,
            }}
            disabled={!to || !from}
            variant="contained"
            color="primary"
            type="button"
            startIcon={<GetAppIcon />}
            onClick={() => {
              handleSaveCsv()
            }}
          >
            Save CSV
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={0} style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={12}>
          <TransactionDialog
            key={+new Date() + Math.random()}
            setOpen={setOpenRefund}
            setSelected={setSelected}
            setTransaction={setTransaction}
            data={transaction}
            open={openRefund}
            to={to}
            from={from}
            refetchTransaction={refetchTransaction}
          />
          <TransactionOrderDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setTransaction={setTransaction}
            data={transaction}
            open={open}
            to={to}
            from={from}
            refetchTransaction={refetchTransaction}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            rowsPerPage={10}
            clickOnSelect={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setTransaction}
            tableData={transactionhistory}
            setOpen={setOpenRefund}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
      <div>Total Amount: {parseInt(totalAmount).toFixed(2)}</div>
    </div>
  )
}
