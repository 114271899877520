import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles, fade } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import FilterListIcon from '@material-ui/icons/FilterList'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Moment from 'moment-timezone'

import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import DirectionsIcon from '@material-ui/icons/Directions'
import InputAdornment from '@material-ui/core/InputAdornment'
import CancelIcon from '@material-ui/icons/Cancel'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const useHeaderStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const classesHead = useHeaderStyles()

  return (
    <TableHead>
      <TableRow classes={{ root: classes.tableHead }}>
        <TableCell
          classes={{
            head: classesHead.head,
          }}
          padding="checkbox"
        ></TableCell>
        {props.tableHead &&
          props.tableHead.map(
            (headCell, i) =>
              !headCell.hidden && (
                <TableCell
                  classes={{
                    head: classesHead.head,
                  }}
                  key={`header-${i}`}
                  className={i === 0 ? classes.hide : null}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'default' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              )
          )}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tableHead: PropTypes.array.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
  searchText: {
    marginTop: 0,
    marginBottom: 10,
    maxWidth: 500,
    marginRight: 0,
    padding: 0,
  },
  root: {
    minHeight: 0,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  /* highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        }, */
  title: {
    flex: '1 1 100%',
  },
}))

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&$focused': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      /* boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`, */
      /* borderColor: theme.palette.primary.main, */
    },
  },
  focused: {
    color: 'black',
  },
}))

function RedditTextField(props) {
  const classes = useStylesReddit()

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  )
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const classesReddit = useStylesReddit()
  const { numSelected, setSearchText, searchText } = props

  return (
    <Toolbar
      variant="dense"
      className={clsx(classes.root, {
        /* [classes.highlight]: numSelected > 0 */
      })}
    >
      {/* <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={e => {
            setSearchText(e.target.value)
          }}
        />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
        >
          <DirectionsIcon />
        </IconButton>
      </Paper> */}
      {/* {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : ( */}
      <React.Fragment>
        {!props.hideSearch && (
          <TextField
            name="SearchText"
            className={classes.searchText}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            label="Search"
            margin="dense"
            variant="outlined"
            fullWidth
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchText.length > 0 && (
                    <IconButton
                      color="primary"
                      className={classes.iconButton}
                      aria-label="directions"
                      onClick={() => {
                        setSearchText('')
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </React.Fragment>
      {/* )} */}

      {numSelected > 0 ? (
        <React.Fragment>
          {props.tableButtons &&
            props.tableButtons.map((tableButton, i) => (
              <Tooltip key={`button-${i}`} title={tableButton.name}>
                <IconButton
                  disabled={tableButton.disabled}
                  onClick={() => {
                    tableButton.method()
                  }}
                  aria-label="change"
                >
                  <tableButton.icon />
                </IconButton>
              </Tooltip>
            ))}
          {!props.hideChange && (
            <Tooltip title="Change">
              <IconButton
                onClick={() => {
                  props.setOpen(true)
                }}
                aria-label="change"
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          )}
          {!props.hideDelete && (
            <Tooltip title="Remove">
              <IconButton
                onClick={() => {
                  props.setConfirm(true)
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  setConfirm: PropTypes.func,
  numSelected: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  hideDelete: PropTypes.bool,
  hideChange: PropTypes.bool,
  tableButtons: PropTypes.array,
  setSearchText: PropTypes.func,
  searchText: PropTypes.string,
  hideSearch: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    /* minWidth: 750, */
    backgroundColor: 'transparent',
  },
  tableWrapper: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paginationRoot: {
    paddingLeft: 0,
  },
  tableHead: {
    height: 38,
  },
}))

export default function EnhancedTable(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(true)
  const [tableDataState, setTableDataState] = React.useState(
    props.tableData || []
  )
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage || 5)
  const [searchText, setSearchText] = React.useState('')

  useEffect(() => {
    function filterList(q, list) {
      function escapeRegExp(s) {
        return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
      }
      const words = q
        .split(/\s+/g)
        .map((s) => s.trim())
        .filter((s) => !!s)
      const hasTrailingSpace = q.endsWith(' ')
      const searchRegex = new RegExp(
        words
          .map((word, i) => {
            if (i + 1 === words.length && !hasTrailingSpace) {
              // The last word - ok with the word being "startswith"-like
              return `(?=.*\\b${escapeRegExp(word)})`
            } else {
              // Not the last word - expect the whole word exactly
              return `(?=.*\\b${escapeRegExp(word)}\\b)`
            }
          })
          .join('') + '.+',
        'gi'
      )
      return list.filter((item) => {
        return searchRegex.test(resolvePath(item, props.tableHead[1].id))
      })
    }

    function filterList2(string, array) {
      return array.filter((o) =>
        Object.keys(o).some((k, i) => {
          //console.log('search', o, k)
          return (
            o[k] &&
            o[k]
              .toString()
              .toLowerCase()
              .includes(string.toLowerCase().split('-').join(''))
          )
        })
      )
    }

    /* function filterList2(q, list) {
      return list.filter(item => {
        return (
          resolvePath(item, props.tableHead[1].id) &&
          resolvePath(item, props.tableHead[1].id)
            .toLowerCase()
            .includes(q.toLowerCase())
        )
      })
    } */

    if (
      props &&
      props.tableHead.length > 0 &&
      props.tableHead[1].id &&
      props.tableData.length > 0
    ) {
      /* const filtered = props.tableData.filter(data =>
        data[props.tableHead[1].id]
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ) */
      if (searchText.length > 0) {
        setTableDataState(filterList2(searchText, props.tableData))
        handleChangePage(null, 0)
        return
      }

      setTableDataState(props.tableData)
    }
  }, [searchText])

  useEffect(() => {
    //setSearchText('')

    if (searchText.length === 0) {
      setTableDataState(props.tableData)
    }
  }, [props])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    /* if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    } */
    props.setSelected([])
  }

  const handleClick = (event, row) => {
    //const selectedIndex = selected.indexOf(row.name)
    //let newSelected = selected.filter(select => select == row.name)

    /* if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    } */

    const selectedIndex = props.selected.indexOf(row.id)
    let newSelected = []

    if (props.multiSelect) {
      if (props.selected.find((a) => a === row.id)) {
        newSelected = props.selected.filter((a) => a !== row.id)
        newSelected = [...newSelected]
      } else newSelected = [...props.selected, row.id]
    } else newSelected.push(row.id)

    props.tableState(row)
    props.setSelected(newSelected)

    if (props.clickOnSelect) props.setOpen(true)

    /* if (props.refetch)
      props.refetch.map(fetch => {
        fetch()
      }) */
  }

  const handleChangePage = (event, newPage, test) => {
    props.paginationFetchmore && props.paginationFetchmore(rowsPerPage, newPage)
    setPage(newPage)
    props.setSelected([])
    props.tableState({})
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    props.paginationFetchmore &&
      props.paginationFetchmore(rowsPerPage, rowsPerPage)
    setPage(0)
    props.setSelected([])
    props.tableState({})
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const isSelected = (id) => props.selected.indexOf(id) !== -1

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, tableDataState.length - page * rowsPerPage)

  const resolvePath = (object, path, defaultValue) =>
    path && path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)

  const tableData = !props.paginationFetchmore
    ? !props.hidePagination
      ? stableSort(tableDataState, getSorting(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : stableSort(tableDataState, getSorting(order, orderBy))
    : tableDataState

  const trunc = (word, n, useWordBoundary) => {
    if (word.length <= n) {
      return word
    }
    word = word.replace(/[^a-zA-Z ]/g, '')

    var subString = word.substr(0, n - 1)
    //return word
    //return subString + '..'
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString) + '..'
    )
  }

  const convertDateTime = (date) => {
    if (!date) return
    date = date.replace('Z', '')
    return date.replace('T', ' ')
  }

  const isToday = (someDate) => {
    const today = new Date()
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    )
  }

  const linkColor = (status, orderdate) => {
    let color
    if (!isToday(new Date(orderdate))) {
      color = 'red'
    } else {
      color = 'blue'
    }
    /* if ($order->Status == "Cancelled" or $order->Status == "Closed" or $order->Status == "Miss Pickup" or $order->Status == "Out Of Area"){
      $color="blue";
    }
    if ($order->Status == "Completed"){
      $color="grey";
    } */
    if (
      status == 'Cancelled' ||
      status == 'Closed' ||
      status == 'Miss Pickup' ||
      status == 'Out Of Area'
    )
      color = 'blue'
    if (status == 'Completed') color = 'grey'

    return color
  }

  return (
    <div className={classes.root}>
      {!props.disableSelection && (
        <EnhancedTableToolbar
          setConfirm={props.setConfirm}
          setOpen={props.setOpen}
          searchText={searchText}
          setSearchText={setSearchText}
          numSelected={props.selected.length}
          hideDelete={props.hideDelete}
          hideChange={props.hideChange}
          hideSearch={props.hideSearch}
          tableButtons={props.tableButtons}
        />
      )}
      <div className={classes.tableWrapper}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={!props.size ? 'small' : props.size}
            aria-label="enhanced table"
            stickyHeader={true}
          >
            <EnhancedTableHead
              tableHead={props.tableHead}
              classes={classes}
              numSelected={props.selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableDataState.length}
            />
            <TableBody>
              {tableData.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`body-${index}`}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>

                    {props.tableHead &&
                      props.tableHead.map((headCell, i) => (
                        <React.Fragment key={i}>
                          {i === 0 && (
                            <TableCell
                              style={{ width: headCell.width || 'auto' }}
                              key={`empty-${i}`}
                              className={classes.hide}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {resolvePath(row, headCell.id)}
                            </TableCell>
                          )}
                          {i > 0 && !headCell.hidden && (
                            <TableCell
                              style={{ width: headCell.width || 'auto' }}
                              align={headCell.numeric ? 'right' : 'left'}
                              key={`data-${i}`}
                              component="th"
                              scope="row"
                              padding={
                                headCell.disablePadding ? 'default' : 'default'
                                //headCell.disablePadding ? 'default' : 'default'
                              }
                            >
                              {headCell.icon &&
                                headCell.icon[0].if !=
                                  resolvePath(row, headCell.id) &&
                                resolvePath(row, headCell.id)}
                              {headCell.icon &&
                                headCell.icon[0].if ==
                                  resolvePath(row, headCell.id) && (
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid item>{headCell.icon[0].show}</Grid>
                                    <Grid item style={{ paddingLeft: 5 }}>
                                      {headCell.icon[0].if}
                                    </Grid>
                                  </Grid>
                                )}
                              {headCell.date &&
                                resolvePath(row, headCell.id) &&
                                Moment(resolvePath(row, headCell.id))
                                  .tz('Asia/Kuala_Lumpur')
                                  .format('DD/MM/YYYY, h:mm a')}
                              {headCell.log &&
                                resolvePath(row, headCell.id) &&
                                `${resolvePath(
                                  row,
                                  headCell.id
                                )} - ${new Intl.DateTimeFormat('en-GB', {
                                  year: '2-digit',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                }).format(
                                  new Date(resolvePath(row, headCell.logdate))
                                )} `}

                              {headCell.datetime &&
                                resolvePath(row, headCell.id) &&
                                Moment(resolvePath(row, headCell.id))
                                  .tz('Asia/Kuala_Lumpur')
                                  .format('DD/MM/YYYY, h:mm a')}
                              {headCell.datetime2 &&
                                resolvePath(row, headCell.id) &&
                                new Intl.DateTimeFormat('en-GB', {
                                  year: '2-digit',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                }).format(
                                  new Date(
                                    resolvePath(row, headCell.id) &&
                                      resolvePath(row, headCell.id)
                                        .replace('T', ' ')
                                        .replace('Z', '')
                                  )
                                )}
                              {headCell.currency &&
                                parseFloat(
                                  resolvePath(row, headCell.id) || 0
                                ).toFixed(2)}
                              {headCell.weight &&
                                parseFloat(
                                  resolvePath(row, headCell.id) || 0
                                ).toFixed(1)}
                              {headCell.trunc &&
                                trunc(
                                  resolvePath(row, headCell.id) ||
                                    resolvePath(row, headCell.ifEmpty) ||
                                    '',
                                  20,
                                  true
                                )}
                              {headCell.awb &&
                                resolvePath(row, headCell.id) &&
                                resolvePath(row, headCell.id).replace(
                                  /(\w{3})(\w{4})(\w{4})/,
                                  '$1-$2-$3'
                                )}
                              {headCell.schedulecolor && (
                                <span
                                  style={{
                                    color: linkColor(
                                      headCell.status,
                                      headCell.orderdate
                                    ),
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {resolvePath(row, headCell.id)}
                                </span>
                              )}
                              {headCell.statuscolor && (
                                <span
                                  style={{
                                    color:
                                      resolvePath(row, headCell.id) ==
                                        'Completed' ||
                                      resolvePath(row, headCell.id) ==
                                        'Checked In'
                                        ? 'green'
                                        : 'red',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {resolvePath(row, headCell.id)}
                                </span>
                              )}
                              {headCell.address &&
                                resolvePath(row, headCell.id) &&
                                resolvePath(row, headCell.id)
                                  .split('\n')
                                  .map((it, i) => (
                                    <div key={'x' + i}>{it}</div>
                                  ))}
                              {!headCell.log &&
                                !headCell.schedulecolor &&
                                !headCell.datetime &&
                                !headCell.datetime2 &&
                                !headCell.address &&
                                !headCell.date &&
                                !headCell.awb &&
                                !headCell.trunc &&
                                !headCell.icon &&
                                !headCell.currency &&
                                !headCell.weight &&
                                !headCell.statuscolor &&
                                resolvePath(row, headCell.id)}
                            </TableCell>
                          )}
                        </React.Fragment>
                      ))}

                    {/* <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                )
              })}
              {props.tableTotal && (
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 'bold' }}
                    align={props.tableTotal.alignTitle}
                    colSpan={props.tableTotal.colSpan}
                  >
                    {props.tableTotal.title}
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold' }}
                    align={props.tableTotal.alignAmount}
                  >
                    {props.tableTotal.amount}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!props.hidePagination && (
        <TablePagination
          classes={{
            toolbar: classes.paginationRoot,
          }}
          labelRowsPerPage=""
          rowsPerPageOptions={[5]}
          component="div"
          count={
            props.paginationTotal
              ? props.paginationTotal
              : tableDataState.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
