import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-boost'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloProvider } from '@apollo/react-hooks'

import gql from 'graphql-tag'
import { setContext } from 'apollo-link-context'
import { Router } from '@reach/router'
import { navigate } from '@reach/router'
import App from './App'
import 'typeface-roboto'
import './index.css'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

const wsLink = new WebSocketLink({
  uri: `ws://localhost:5000/`,
  options: {
    reconnect: true,
  },
})

const httpLink = new HttpLink({
  uri: '/api/graphql',
  credentials: 'include',
})

const cache = new InMemoryCache()

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link,
  cache,
})

cache.writeData({
  data: {
    isDarkModeEnabled: false,
    loginDetails: { StaffID: '', Department: '' },
  },
})

/*gg const client = new ApolloClient({
  uri: '/api/graphql'
}) */

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)
